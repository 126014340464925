.mat-checkbox {
  &.thin {
    .mat-ripple {
      display: none;
    }
    label {
      margin-bottom: 8px;
    }
  }
  &.sm {
    label {
      .mat-checkbox-label {
        line-height: 11px;
      }
    }
  }
}
