@media print {
  body {
    font-size: 12px;
    line-height: 1.2;
  }

  nav.navbar-static-side {
    display: none;
  }

  #page-wrapper {
    margin: 0;
  }
}
