@import 'apps/transect/src/assets/styles/variables';

.expansion-panel {
  & > .mat-expansion-panel-header {
    background-color: $gray-extra-light;
    border-radius: 0;

    &.mat-expanded {
      height: 3.2em;
      border-bottom: 0.08em solid $gray-light;

      &:focus,
      &:hover {
        background-color: $gray-extra-light;
      }
    }

    & .mat-expansion-panel-header-title {
      align-items: center;
    }

    &[aria-disabled='true'] {
      cursor: default;

      .mat-expansion-panel-header-title {
        color: rgba($black, 0.87);
      }
      .mat-expansion-panel-header-description {
        color: rgba($black, 0.54);
      }
    }
  }

  & .mat-expansion-panel-header-description {
    display: block;
    text-align: end;
    margin-right: 0;
  }
}
