@font-face {
  font-family: 'icomoon';
  src: url('../fonts/custom-icons/icomoon.eot?uvq9qm');
  src: url('../fonts/custom-icons/icomoon.eot?uvq9qm#iefix')
      format('embedded-opentype'),
    url('../fonts/custom-icons/icomoon.ttf?uvq9qm') format('truetype'),
    url('../fonts/custom-icons/icomoon.woff?uvq9qm') format('woff'),
    url('../fonts/custom-icons/icomoon.svg?uvq9qm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icomoon-icon-'],
[class*=' icomoon-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-icon-shpo:before {
  content: '\e900';
  color: #34686c;
}
.icomoon-icon-think-peaks:before {
  content: '\e901';
  color: #34686c;
}
