.mat-card {
  // angularjs material use to have this approach where it uses invert to swap the background and foreground color.
  &.primary-invert-card {
    height: 100%;
    background-color: lighten($brand-primary-light, 44%);
    color: darken($brand-primary, 10%);

    & > .mat-card-content {
      background-color: $white;
      padding: 1.2rem;
    }
  }
}
