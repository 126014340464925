.mat-radio-button {
  &.sm {
    .mat-radio-container {
      height: 12px;
      width: 12px;
      .mat-radio-outer-circle {
        height: 12px;
        width: 12px;
      }
      .mat-radio-inner-circle {
        height: 12px;
        width: 12px;
      }
    }
  }
}
