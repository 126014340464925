@use '@angular/material' as mat;
// Variables
@import './assets/styles/variables';

// Custom Icons
@import './assets/icons/style.css';

@import './assets/styles/quill.core';
@import './assets/styles/quill.snow';

// Primary Components
@import './assets/styles/print';
@import './assets/styles/custom';
@import './assets/styles/components/button';
@import './assets/styles/components/card';
@import './assets/styles/components/expansion-panel';
@import './assets/styles/components/radiobutton';
@import './assets/styles/components/tootltip';
@import './assets/styles/components/formfield';
@import './assets/styles/components/checkbox';
@import './assets/styles/components/slidetoggle';
@import './app/modules/notification/notification.component.theme.scss';

// Component Imports for Specific
@import 'mapbox-gl/dist/mapbox-gl';
@import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
@import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder';
@import 'ag-grid-enterprise/dist/styles/ag-grid';
@import 'ag-grid-enterprise/dist/styles/ag-theme-balham.min.css';
@import 'ag-grid-enterprise/dist/styles/ag-theme-material.min.css';
@import '@watergis/mapbox-gl-export/css/styles.css';

@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';

@import '@angular/cdk/overlay-prebuilt.css';
@import './assets/styles/themes/light-theme';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import './app/modules/transect-modal/transect-modal/transect-modal.component.scss';

@include mat.legacy-core();
@include mat.core();

@include mat.all-legacy-component-typographies();
@include mat.all-legacy-component-themes($light-theme);
@include mat.all-component-typographies();
@include mat.all-component-themes($light-theme);

// Component Themes
@include notification-theme($light-theme);

.dragging-container {
  position: relative;
}

.draggable-handle {
  cursor: grab;
}

.text-danger {
  color: $transect-red;
}

.comment-icon {
  color: $secondary-colors-secondary-color;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.executive-summary-more-btn {
  background-color: white;
  color: #34686c;
  border: 1px solid rgba($color: #34686c, $alpha: 0.5);
  text-decoration: none;
  padding: 0.5rem 2rem;
  border-radius: 0.25rem;
  font-weight: bold;

  &:hover {
    background-color: #34686c;
    color: white;
    text-decoration: none;
  }

  transition: background-color, color 150ms ease-in-out;
}

.submasthead {
  background-color: #eee;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  margin-top: -20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.submasthead .container > *:last-child {
  margin-bottom: 0;
}

.submasthead h1,
.submasthead h2,
.submasthead h3,
.submasthead h4,
.submasthead h5,
.submasthead h6 {
  margin-top: 0;
}

.cdk-overlay-container {
  .grid-column-filter-overlay-panel {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

.spin-animation {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

button[disabled] {
  opacity: 0.7;
}

a.mat-button-base {
  outline: none;
  text-decoration: none;
}

html {
  height: 100%;
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: sans-serif;

  body {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Helvetica Neue, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.428571429;
    color: #616161;
    background-color: #fff;
    margin: 0;

    &.overflow-hidden {
      overflow: hidden;
    }
  }

  textarea {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Helvetica Neue, Arial, sans-serif;
    font-size: 14px;
  }

  .container {
    max-width: 1170px;
    margin: auto;
    padding: 0 15px;
  }

  a,
  button {
    cursor: pointer;
  }

  a {
    color: #34686c;
    text-decoration: none;
    font-size: 12px;

    &:hover,
    &:focus {
      color: #62969a;
      text-decoration: none;
    }
  }

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  .alert {
    padding: 15px;
    margin-bottom: 18px;
    border: 1px solid transparent;
    border-radius: 4px;
    .alert-link {
      font-weight: 700;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
  }

  .alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
  }

  .alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
  }

  h1,
  h2,
  h3 {
    margin-top: 18px;
    margin-bottom: 9px;
  }

  h4,
  h5,
  h6 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  h1 {
    font-size: 33px;
  }

  h2 {
    font-size: 27px;
  }

  h3 {
    font-size: 23px;
  }

  h4 {
    font-size: 17px;
  }

  h5 {
    font-size: 13px;
    font-weight: 700;
  }

  dl {
    margin-top: 0;
    margin-bottom: 18px;
  }

  dl:not(.dl-horizontal) dt:not(:first-child) {
    margin-top: 5px;
  }

  dt {
    font-weight: 700;
  }

  dd,
  dt {
    line-height: 1.428571429;
  }

  dd {
    margin-left: 0;
  }

  .table-bordered,
  .table-bordered > tbody > tr > td,
  .table-bordered > tbody > tr > th,
  .table-bordered > tfoot > tr > td,
  .table-bordered > tfoot > tr > th,
  .table-bordered > thead > tr > td,
  .table-bordered > thead > tr > th {
    border: 1px solid #ddd;
  }

  .table,
  .table-dashboard-panel {
    width: 100%;
    max-width: 100%;
    margin-bottom: 18px;
  }

  table {
    background-color: initial;
    border-collapse: collapse;
    border-spacing: 0;
  }

  caption,
  th {
    text-align: left;
  }
  td,
  th {
    padding: 0;
  }

  .table-dashboard-panel > caption + thead > tr:first-child > td,
  .table-dashboard-panel > caption + thead > tr:first-child > th,
  .table-dashboard-panel > colgroup + thead > tr:first-child > td,
  .table-dashboard-panel > colgroup + thead > tr:first-child > th,
  .table-dashboard-panel > thead:first-child > tr:first-child > td,
  .table-dashboard-panel > thead:first-child > tr:first-child > th,
  .table > caption + thead > tr:first-child > td,
  .table > caption + thead > tr:first-child > th,
  .table > colgroup + thead > tr:first-child > td,
  .table > colgroup + thead > tr:first-child > th,
  .table > thead:first-child > tr:first-child > td,
  .table > thead:first-child > tr:first-child > th {
    border-top: 0;
  }

  .table-bordered > thead > tr > td,
  .table-bordered > thead > tr > th {
    border-bottom-width: 2px;
  }

  .table-bordered,
  .table-bordered > tbody > tr > td,
  .table-bordered > tbody > tr > th,
  .table-bordered > tfoot > tr > td,
  .table-bordered > tfoot > tr > th,
  .table-bordered > thead > tr > td,
  .table-bordered > thead > tr > th {
    border: 1px solid #ddd;
  }

  .table-condensed > tbody > tr > td,
  .table-condensed > tbody > tr > th,
  .table-condensed > tfoot > tr > td,
  .table-condensed > tfoot > tr > th,
  .table-condensed > thead > tr > td,
  .table-condensed > thead > tr > th {
    padding: 5px;
  }

  .table-dashboard-panel > thead > tr > th,
  .table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
  }

  .table-dashboard-panel > tbody > tr > td,
  .table-dashboard-panel > tbody > tr > th,
  .table-dashboard-panel > tfoot > tr > td,
  .table-dashboard-panel > tfoot > tr > th,
  .table-dashboard-panel > thead > tr > td,
  .table-dashboard-panel > thead > tr > th,
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    padding: 8px;
    line-height: 1.428571429;
    vertical-align: top;
    border-top: 1px solid #ddd;
  }

  .table-bordered,
  .table-bordered > tbody > tr > td,
  .table-bordered > tbody > tr > th,
  .table-bordered > tfoot > tr > td,
  .table-bordered > tfoot > tr > th,
  .table-bordered > thead > tr > td,
  .table-bordered > thead > tr > th {
    border: 1px solid #ddd;
  }

  .table-condensed > tbody > tr > td,
  .table-condensed > tbody > tr > th,
  .table-condensed > tfoot > tr > td,
  .table-condensed > tfoot > tr > th,
  .table-condensed > thead > tr > td,
  .table-condensed > thead > tr > th {
    padding: 5px;
  }
  .table-dashboard-panel > tbody > tr > td,
  .table-dashboard-panel > tbody > tr > th,
  .table-dashboard-panel > tfoot > tr > td,
  .table-dashboard-panel > tfoot > tr > th,
  .table-dashboard-panel > thead > tr > td,
  .table-dashboard-panel > thead > tr > th,
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    padding: 8px;
    line-height: 1.428571429;
    vertical-align: top;
    border-top: 1px solid #ddd;
  }
  .mapboxgl-export-list {
    left: auto;
    right: 40px;
  }
}

.transparent {
  opacity: 0;
}

.proprietary-checkbox {
  .mat-checkbox-inner-container {
    margin-top: 0.5rem;
    margin-right: 1rem;
  }
  .mat-checkbox-layout .mat-checkbox-label {
    line-height: normal;
  }
}

.text-muted {
  opacity: 0.5;
}

.parcel-table-modal .mat-dialog-container,
.mini-report-expert-notes-container .mat-dialog-container,
.save-template-name-type-modal .mat-dialog-container,
.save-project-name-type-modal .mat-dialog-container,
.dialog-modal .mat-dialog-container,
.solar-pulse-dialog-modal .mat-dialog-container {
  padding: 0px;
}

.buildable-area {
  .mat-dialog-container {
    padding: 0px;
    overflow: hidden;
  }
  .mat-dialog-content {
    margin: 0 !important;
  }
  .mat-dialog-actions {
    margin-bottom: 0 !important;
  }
  .mat-checkbox-layout .mat-checkbox-label {
    margin-left: 20px;
    color: $shades-gray-dark;
    font-weight: 500;
  }
}
.project-context-fields .mat-radio-label-content {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: $shades-gray-dark;
}

.context-fields-slider .mat-slider-track-fill,
.context-fields-slider .mat-slider-wrapper,
.context-fields-slider .mat-slider-track-wrapper,
.context-fields-slider .mat-slider-track-background {
  height: 3px !important;
}

.mat-tooltip.active-contested-farm-tooltip {
  font-size: 12px !important;
}
.mat-mdc-menu-panel.landowner-phone-email-popup {
  width: 300px !important;
}
