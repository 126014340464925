.mat-form-field {
  // flexible class allows all child containers of mat-form-field to have width = 100% so they can be styled through
  // the parent;
  &.ts-mat-form-field-flexible {
    width: 100%;
    .mat-form-field-infix {
      width: 100%;
    }
  }
}
