@import 'apps/transect/src/assets/styles/variables';
@import 'libs/ui/src/styles/palette';
@import 'libs/ui/src/styles/typography';

.transect-modal-panel {
  border-radius: 0.7rem;
  overflow: hidden;
}

ts-transect-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .content {
    background-color: white;
  }

  .top-bar {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $shades-gray-extra-light;
    background: $shades-gray-ultra-light;
    padding: 19px 32px;
    .btn-close {
      color: $shades-gray-light;
      height: 20px;
    }
    .title {
      font-size: 2rem;
      font-weight: 700;
      color: $shades-black;
      // Close button is 30px wide
      max-width: calc(100% - 30px);
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .body {
    flex: 1;
    background-color: white;
  }
}
