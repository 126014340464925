.mat-slide-toggle {
  &.sm {
    .mat-slide-toggle-bar {
      height: 5px;
      width: 26px;
      .mat-slide-toggle-thumb {
        width: 11px;
        height: 11px;
      }
    }
  }
}
