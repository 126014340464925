$gray-light: #ccc;
$gray-extra-light: #eee;
$gray-ultra-light: #fbfbfb;
$gray-dark: #616161;
$gray-inactive: #c1c1c1;
$black: #000;
$white: #fff;
$transect-teal: #34686c;
$transect-red: #cc0000;
$brand-primary: $transect-teal;
$brand-primary-light: #62969a;
$brand-primary-extra-light: #cbdedf;
$brand-primary-dark: #003d41;
$brand-secondary: #f6f9fa;
$brand-danger: #f44336;
$brand-danger-light: rgb(243, 221, 221);
$brand-danger-dark: #a50000;
$brand-success: #499d5d;
$brand-success-light: #e6ffe6;
$brand-disabled: #e0e0e0;
$brand-warning: #f6ae2d;
$brand-warning-light: #fef8eb;
$brand-info: #5ea9c1;
$brand-hint-color: #a4a4a4;
$link-hover-color: #62969a;
$font-size-base: 13px;

$secondary-color: #df7400;
$secondary-color-dark: #d46f00;

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif;
$font-family-serif: Georgia, 'Times New Roman', Times, serif;

$grid-float-breakpoint: 992px; // $screen-sm-min
