a,
button {
  & .mat-icon {
    &.fas,
    &.far {
      font-size: 1.8rem;
      margin: 0 0.4rem;
      vertical-align: text-top;
    }
  }
}
