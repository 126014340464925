@import './palette';

@mixin transect-font {
  $font-family: 'Roboto', 'Helvetica', 'sans-serif';
  margin: 0;
}

.display-large {
  @include transect-font;
  font-size: 57px;
  font-weight: bold;
  line-height: 1.12;
  letter-spacing: -0.25px;
}

.display-medium {
  @include transect-font;
  font-size: 45px;
  font-weight: bold;
  line-height: 1.16;
}

.display-small {
  @include transect-font;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.22;
}

.headline-large {
  @include transect-font;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
}

.headline-medium {
  @include transect-font;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.29;
}

.headline-small {
  @include transect-font;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
}

.title-large {
  @include transect-font;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.27;
}

.title-medium {
  @include transect-font;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.1px;
}

.body-large {
  @include transect-font;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.title-small {
  @include transect-font;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.1px;
}

.label-large {
  @include transect-font;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 0.1px;
}

.body-medium {
  @include transect-font;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
}

.label-medium {
  @include transect-font;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.5px;
}

.body-small {
  @include transect-font;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.4px;
}

.label-small {
  @include transect-font;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: 0.5px;
}

@each $color-name, $color-value in $color-map {
  .text-#{$color-name} {
    color: $color-value;
  }
}
