@import '@angular/material';
@import '@angular/material/theming';

@mixin notification-color($config-or-theme) {
  $config: mat-get-color-config($config-or-theme);
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $warn: map-get($config, warn);
  $foreground: map-get($config, foreground);
  $background: map-get($config, background);

  ts-notification-panel {
    .notification-panel {
      background-color: mat-color($accent, 50);

      .notification-list-container {
        background-color: mat-color($background, card);
        color: mat-color($foreground, text);
      }
    }
  }
  ts-notification-large-panel {
    .notification-panel {
      background-color: white;

      .notification-list-container {
        color: mat-color($foreground, text);
      }
    }
  }

  ts-notification-item {
    .notification-container {
      &:hover {
        background-color: mat-color($accent, 100);
        color: mat-color($accent, '100-contrast');
      }

      .icon-container {
        .icon-circle {
          background-color: mat-color($primary, 500);
          color: mat-color($primary, '500-contrast');
        }
      }
    }
  }
}

@mixin notification-theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat-get-color-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color != null {
    @include notification-color($color);
  }
}
