@import 'libs/ui/src/styles/palette';
.transect-nx-option,
.transect-nx-menu-item {
  i {
    color: $shades-gray-light;
  }
  &:hover,
  &:focus {
    i {
      color: $brand-colors-brand-primary-light;
    }
  }
  &:active,
  &.selected {
    i {
      color: $shades-white;
    }
  }

  &.disabled {
    i {
      color: $gray-inactive;
    }
    &:hover,
    &:focus {
      i {
        color: $gray-inactive;
      }
    }
  }
}
