// Whites
$shades-white: #fff;
$transect-colors-white: #fff;

// Grays
$shades-gray-ultra-light: #fbfbfb;
$shades-gray-extra-light: #eee;
$shades-gray-light: #ccc;
$shades-gray-dark: #616161;

// Brand Primary
$brand-colors-brand-primary-extra-light: #cbdedf;
$brand-colors-brand-primary-light: #62969a;
$brand-colors-brand-primary: #34686c;
$brand-colors-brand-primary-dark: #003d41;

// Brand Secondary
$brand-colors-brand-secondary: #f6f9fa;

// Secondary Colors
$secondary-colors-secondary-color: #df7400;
$secondary-colors-secondary-color-dark: #d46f00;

// Transect Colors
$transect-colors-ref-neutral-neutral-40: #605d62;
$transect-colors-ref-neutral-neutral-90: #e6e1e5;

// Success
$success-brand-success-light: #f1f9f3;
$success-brand-success: #499d5d;

// Warning
$danger-warnings-brand-warning-extra-light: #fef8eb;
$danger-warnings-brand-warning: #f6ae2d;

// Danger
$danger-warnings-brand-danger-light: #feedec;
$danger-warnings-brand-danger: #f44336;
$danger-warnings-brand-danger-dark: #ae1409;

// Info
$info-brand-info: #5ea9c1;

$info-brand-info-bright: #42d1ff;

// Black
$shades-black: #000;

// Disabled
$gray-inactive: #c1c1c1;

$color-map: (
  'shades-white': $shades-white,
  'shades-gray-ultra-light': $shades-gray-ultra-light,
  'shades-gray-extra-light': $shades-gray-extra-light,
  'shades-gray-light': $shades-gray-light,
  'shades-gray-dark': $shades-gray-dark,
  'brand-colors-brand-primary-extra-light':
    $brand-colors-brand-primary-extra-light,
  'brand-colors-brand-primary-light': $brand-colors-brand-primary-light,
  'brand-colors-brand-primary': $brand-colors-brand-primary,
  'brand-colors-brand-primary-dark': $brand-colors-brand-primary-dark,
  'brand-colors-brand-secondary': $brand-colors-brand-secondary,
  'secondary-colors-secondary-color': $secondary-colors-secondary-color,
  'secondary-colors-secondary-color-dark':
    $secondary-colors-secondary-color-dark,
  'transect-colors-ref-neutral-neutral-40':
    $transect-colors-ref-neutral-neutral-40,
  'transect-colors-ref-neutral-neutral-90':
    $transect-colors-ref-neutral-neutral-90,
  'success-brand-success-light': $success-brand-success-light,
  'success-brand-success': $success-brand-success,
  'danger-warnings-brand-warning-extra-light':
    $danger-warnings-brand-warning-extra-light,
  'danger-warnings-brand-warning': $danger-warnings-brand-warning,
  'danger-warnings-brand-danger-light': $danger-warnings-brand-danger-light,
  'danger-warnings-brand-danger': $danger-warnings-brand-danger,
  'danger-warnings-brand-danger-dark': $danger-warnings-brand-danger-dark,
  'info-brand-info': $info-brand-info,
  'shades-black': $shades-black,
  'gray-inactive': $gray-inactive,
);

@each $color-name, $color-value in $color-map {
  .background-color-#{$color-name} {
    background-color: $color-value;
  }
}
@each $color-name, $color-value in $color-map {
  .border-color-#{$color-name} {
    border-color: $color-value;
  }
}
